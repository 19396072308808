<form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field class="form-control">
    <mat-label>Email</mat-label>
    <input matInput formControlName="email" type="text" autocomplete="off" />
    <mat-error *ngIf="email?.hasError('required')">
      Merci de saisir votre Email
    </mat-error>
    <mat-error *ngIf="email?.hasError('email')">
      Adresse Email invalide. Merci de saisir une adresse valide.
    </mat-error>
  </mat-form-field>
  <mat-form-field class="form-control">
    <mat-label>Mot de passe</mat-label>
    <input matInput formControlName="password" type="password" autocomplete="off" />
    <mat-error *ngIf="password?.hasError('required')">
      Un mot de passe est requis.
    </mat-error>
  </mat-form-field>

  <mat-slide-toggle formControlName="register">Créer un compte</mat-slide-toggle>

  <!--  <div class="form-footer">-->
<!--    <button [disabled]="form.invalid" mat-raised-button type="submit">-->
<!--      Submit-->
<!--    </button>-->
<!--  </div>-->
</form>
