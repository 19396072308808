import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Bookmark} from '../models/bookmark.model';
import {ApiService} from './api.service';
import {first, tap} from 'rxjs/operators';
import {ParcelInfo} from '../models/parcel-info.model';
import {Filters} from '../models/filters.model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  filters$ = new BehaviorSubject<Filters | Partial<Filters>>(null);
  filters: Filters | Partial<Filters>;
  bookmarks$ = new BehaviorSubject<Bookmark[]>([]);
  parcelInfo$ = new BehaviorSubject<ParcelInfo>(null);
  selectedTabIndex$ = new Subject<number>();

  constructor(private api: ApiService) { }

  refreshBookmarks(): void {
    this.api.getBookmarks().pipe(
      first(),
      tap(bookmarks => this.bookmarks$.next(bookmarks))
    ).subscribe();
  }

  setFilters(filters: Filters | Partial<Filters>): void {
    if (this.filters) {
      this.filters = {...filters};
    } else {
      this.filters = filters;
    }
    this.filters$.next(filters);
  }
}
