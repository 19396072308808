import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as L from 'leaflet';
import {LatLng, LatLngBounds, Layer} from 'leaflet';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MapService} from '../../services/map.service';
import {BehaviorSubject, combineLatest, EMPTY} from 'rxjs';
import {filter, first, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {ParcelInfo} from '../../models/parcel-info.model';
import {Dvf} from '../../models/dvf.model';
import * as turf from '@turf/turf';
import {City} from '../../models/city.model';
import {TILE_PROVIDERS} from '../../tile-providers';
import {environment} from '../../../environments/environment';
import {DashboardService} from '../../services/dashboard.service';
import {Filters} from '../../models/filters.model';
import {Marker} from '../../models/marker.model';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {

  private map: any;
  private defaultBounds = new LatLngBounds([[48.21493632808529, -1.4727715495973828], [48.0187354582787, -1.8445900920778515]]);
  private currentCity: City;
  private clickedLatlng: LatLng;
  private refreshData$ = new BehaviorSubject(null);
  private selectedParcels = [] as string[];
  private filters$ = this.dashboardService.filters$;
  private markers$ = this.mapService.markers$;
  private bookmarks$ = this.dashboardService.bookmarks$;
  markers: Marker[];
  markerGroup = new L.LayerGroup<any>();

  // https://cadastre.data.gouv.fr/data/etalab-cadastre/2021-02-01/geojson/communes/

  constructor(private http: HttpClient,
              private mapService: MapService,
              private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.mapService.clearMap$.subscribe(c => this.clearMap());
  }

  ngAfterViewInit(): void {
    this.initMap();
    this.map.invalidateSize();

    this.markers$.pipe(
      tap(markers => {
        this.markers = markers;
        this.setMarkers(markers);
      }),
    ).subscribe();

    this.bookmarks$.pipe(
      tap(bookmarks => {
        if (bookmarks.length > 0) {
          this.mapService.getMarkers();
        } else {
          this.markers = [];
          this.setMarkers(this.markers);
        }
      })
    ).subscribe();

    this.mapService.loadParcel$.pipe(
      filter(parcel => parcel !== null),
      tap(parcel => {
        this.clearMap();
        const resultLayer = L.geoJSON(parcel.geoJson, {
          onEachFeature: (feature, layer) => this.onEachFeature(feature, layer, parcel.dvf, true)
        }).setStyle({
          color: '#4169E1',
          weight: 1
        });
        resultLayer.addTo(this.map);
        resultLayer.eachLayer(layer => layer.fireEvent('click'));
        this.map.flyToBounds(resultLayer.getBounds(), { duration: 0.5});
        this.map.invalidateSize();
      }),
    ).subscribe();

    combineLatest([this.filters$, this.refreshData$]).pipe(
      filter(([filters, refreshData]) => filters !== null && filters.city !== null),
      switchMap(([filters, refreshData]) => {
        // this.clearMap();
        if (this.currentCity !== filters.city) {
          if (this.currentCity) {
            this.currentCity.leafletContour.removeFrom(this.map);
          }
          this.currentCity = filters.city;
          this.currentCity.leafletContour = L.polygon(filters.city.contour, {color: 'darkblue', fillColor: 'dodgerblue', weight: 2});
          this.currentCity.leafletContour.addTo(this.map);
          this.map.flyToBounds(this.currentCity.leafletContour.getBounds());

          //this.map.panTo(filters.city.center);
          // if (filters.city.bounds) {
          //   this.map.flyToBounds(filters.city.bounds);
          // } else {
          //   this.map.flyToBounds(this.defaultBounds);
          // }
        }
        if (filters.minArea && this.selectedParcels.length <= 1) {
          this.clearMap();
          return this.mapService.loadParcels(filters as Filters).pipe(
            tap(parcels => {
              L.geoJSON(parcels.geoJson, {
                // @ts-ignore
                style: (feature, layer) => this.applyStyle(feature, layer, parcels.dvf, filters.dvfEnable),
                onEachFeature: (feature, layer) => this.onEachFeature(feature, layer, parcels.dvf, filters.dvfEnable)
              }).addTo(this.map);
              this.map.invalidateSize();
            })
          );
          //           let params = new HttpParams().set('min', String(filters.minArea)).set('max', filters.maxArea ? String(filters.maxArea) : String(filters.minArea)).set('dvf', String(filters.dvfEnable)).set('dvfMinDate', filters.dvfStart.toISOString()).set('dvfMaxDate', filters.dvfEnd.toISOString());
          // return this.http.get(`${environment.apiUrl}/city/${filters.city.insee_code}`, {params}).pipe(
          //   tap((payload: any) => {
          //     L.geoJSON(payload.data.geoJson, {
          //       // @ts-ignore
          //       style: (feature, layer) => this.applyStyle(feature, layer, payload.data.dvf, filters.dvfEnable),
          //       onEachFeature: (feature, layer) => this.onEachFeature(feature, layer, payload.data.dvf, filters.dvfEnable)
          //     }).addTo(this.map);
          //     this.map.invalidateSize();
          //   })
          // );
        } else if (filters.dvfEnable && filters.dvfAll && this.selectedParcels.length <= 1) {
          this.clearMap();
          // let params: HttpParams;
          // if (filters.dvfStart && filters.dvfEnd) {
          //   params = new HttpParams().set('dvf', String(filters.dvfEnable)).set('dvfAll', String(filters.dvfAll)).set('dvfMinDate', filters.dvfStart.toISOString()).set('dvfMaxDate', filters.dvfEnd.toISOString());
          // } else {
          //   params = new HttpParams().set('dvf', String(filters.dvfEnable)).set('dvfAll', String(filters.dvfAll));
          // }
          // return this.http.get(`${environment.apiUrl}/city/${filters.city.insee_code}`, {params}).pipe(
          //   tap((payload: any) => {
          //     L.geoJSON(payload.data.geoJson, {
          //       // @ts-ignore
          //       style: (feature, layer) => this.applyStyle(feature, layer, payload.data.dvf, filters.dvfEnable),
          //       onEachFeature: (feature, layer) => this.onEachFeature(feature, layer, payload.data.dvf, filters.dvfEnable)
          //     }).addTo(this.map);
          //     this.map.invalidateSize();
          //   })
          //);
          return this.mapService.loadParcels(filters as Filters).pipe(
            tap(data => {
              L.geoJSON(data.geoJson, {
                // @ts-ignore
                style: (feature, layer) => this.applyStyle(feature, layer, data.dvf, filters.dvfEnable),
                onEachFeature: (feature, layer) => this.onEachFeature(feature, layer, data.dvf, filters.dvfEnable)
              }).addTo(this.map);
              this.map.invalidateSize();
            })
          );
        } else if (this.clickedLatlng && this.selectedParcels.length <= 1) {
          this.clearMap();
          return this.mapService.lookupParcel(this.clickedLatlng, filters as Filters).pipe(
            tap(data => {
              const resultLayer = L.geoJSON(data.geoJson, {
                onEachFeature: (feature, layer) => this.onEachFeature(feature, layer, data.dvf, filters.dvfEnable)
              }).setStyle({
                color: '#4169E1',
                weight: 1
              });
              resultLayer.addTo(this.map);
              resultLayer.eachLayer(layer => layer.fireEvent('click'));
              this.clickedLatlng = null;
              this.map.invalidateSize();
            })
          );
          // let params = new HttpParams().set('lat', String(this.clickedLatlng.lat)).set('lng', String(this.clickedLatlng.lng)).set('dvf', String(filters.dvfEnable));
          // return this.http.get(`${environment.apiUrl}/city/${filters.city.insee_code}/lookup`, {params}).pipe(
          //   tap((payload: any) => {
          //     const resultLayer = L.geoJSON(payload.data.geoJson, {
          //       onEachFeature: (feature, layer) => this.onEachFeature(feature, layer, payload.data.dvf, filters.dvfEnable)
          //     }).setStyle({
          //       color: '#4169E1',
          //       weight: 1
          //     });
          //     resultLayer.addTo(this.map);
          //     resultLayer.eachLayer(layer => layer.fireEvent('click'));
          //     this.clickedLatlng = null;
          //     this.map.invalidateSize();
          //   })
          // );
        } else {
          return EMPTY;
        }
      })
    ).subscribe(_ => this.mapService.getMarkers());

    this.map.invalidateSize();
    combineLatest([this.mapService.aerial$, this.filters$]).pipe(
      tap(([aerial, filters]) => {
        // console.log(filters);
        if (aerial) {
          TILE_PROVIDERS.map.removeFrom(this.map);
          TILE_PROVIDERS.geoportailFranceOrthos.addTo(this.map);
          // if (filters && filters.city && filters.city.county.code === 35) {
          //   TILE_PROVIDERS.rennesMetroSig.addTo(this.map);
          // } else {
          //   TILE_PROVIDERS.geoportailFranceOrthos.addTo(this.map);
          // }
          TILE_PROVIDERS.cartoDBPositronOnlyLabels.addTo(this.map);
        } else {
          TILE_PROVIDERS.geoportailFranceOrthos.removeFrom(this.map);
          //TILE_PROVIDERS.rennesMetroSig.removeFrom(this.map);
          TILE_PROVIDERS.cartoDBPositronOnlyLabels.removeFrom(this.map);
          TILE_PROVIDERS.map.addTo(this.map);
        }
        this.map.invalidateSize();
      })
    ).subscribe();
  }

  private clearMap(): void {
    this.selectedParcels = [];
    this.map.eachLayer((layer: any) => {
      if (!layer._tileSize) { this.map.removeLayer(layer); }
    });
    this.setMarkers(this.markers);
    if (this.currentCity) {
      this.currentCity.leafletContour.setStyle({color: 'darkblue', fillColor: 'none', weight: 2}).addTo(this.map);
    }
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [48.11198, -1.67429],
      zoomControl: false,
      zoom: 12
    });

    this.markerGroup.addTo(this.map);

    this.map.on('click', (e) => {
      // console.log(this.map.getBounds());
      this.clickedLatlng = e.latlng;
      this.refreshData$.next(null);
    });

    TILE_PROVIDERS.map.addTo(this.map);
  }

  onEachFeature(feature: any, layer: any, dvf?: Dvf | Dvf[], dvfEnable?: boolean): void {
    let dvfMatch: Dvf | Dvf[];
    if (dvf) {
      dvfMatch = dvf[feature.properties.id];
    }
    //console.log(feature);
    // does this feature have a property named popupContent?
    const bbox = L.polygon(feature.geometry.coordinates).getBounds().toBBoxString();
    const bboxCoords = bbox.split(',').map(n => Number(n));
    const p1 = turf.point([bboxCoords[0], bboxCoords[1]]);
    const p2 = turf.point([bboxCoords[2], bboxCoords[3]]);
    const bearing = turf.bearing(p1, p2);
    //48.1356457,-1.5273962,48.1359047,-1.5269776
    const coords = L.polygon(feature.geometry.coordinates).getBounds().getCenter();
    const center = {
      lat: coords.lng,
      lng: coords.lat
    } as LatLng;

    layer.on({
      click: (e) => {
        // Avoid event bubbling up
        L.DomEvent.stopPropagation(e);
        this.map.invalidateSize();
        //this.map.panTo(center);
        // this.mapService.parcelInfo$.next({
        //   properties: feature.properties,
        //   dvf: dvfMatch,
        //   center,
        // } as ParcelInfo);
        this.mapService.setParcelInfo({
          properties: feature.properties,
          dvf: dvfMatch,
          center,
        } as ParcelInfo);
        if (layer.options.color.toLowerCase() === '#90EE90') {
          this.selectedParcels.splice(this.selectedParcels.indexOf(feature.properties.id), 1);
          layer.setStyle(this.applyStyle(feature, layer, dvf, dvfEnable));
        } else {
          this.selectedParcels.push(feature.properties.id);
          layer.setStyle({
            color: '#90ee90'
          });
        }
      },
    });
  }

  // tslint:disable-next-line:typedef
  applyStyle(feature: any, layer: any, dvf: Dvf | Dvf[], dvfEnable: boolean) {
    let color = '#4169E1';
    let weight = 1;
    if (dvfEnable && dvf) {
      const dvfMatch = dvf[feature.properties.id];
      if (dvfMatch) {
        color = '#FF6347';
        weight = 2;
      }
    }
    return {
      color,
      weight
    };
  }

  setMarkers(markers: Marker[]): void {
    this.markerGroup.clearLayers();
    this.markerGroup = new L.LayerGroup(
      markers.map(m => L.marker([m.lat, m.lng]).on('click', (e) => {
        this.dashboardService.selectedTabIndex$.next(1);
        this.mapService.loadParcel(m.parcelId);
      }))
    );
    this.markerGroup.addTo(this.map);
    this.map.invalidateSize();
  }

}
