import {Pipe, PipeTransform} from '@angular/core';
import {Dvf} from '../models/dvf.model';

@Pipe({
  name: 'dvfSquareMeterValue'
})
export class DvfSquareMeterValuePipe implements PipeTransform {

  transform(d: Dvf, ...args: unknown[]): unknown {
    return String(Math.round(Number(d.valeur_fonciere) / Number(d.surface_reelle_bati))).split('').reverse().join('').replace(/([0-9]{3})/g, '$1 ').split('').reverse().join('');
  }

}
