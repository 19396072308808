import { Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  User,
  deleteUser,
  getAuth, signInWithPopup, signInAnonymously,
} from '@angular/fire/auth';
import {LoginData} from '../interfaces/login-data.interface';
import {BehaviorSubject} from 'rxjs';
import * as FirebaseErrorCodes from '../firebase-error-codes';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = new BehaviorSubject<User>(null);

  constructor(private auth: Auth) {
    this.auth.onAuthStateChanged(user => {
      if (user) {
        user.reload().then(_ => this.user$.next(user));
      }
    });
  }

  login({ email, password }: LoginData): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.auth, email, password)
      .then(result => {
        if (result.user) {
          this.user$.next(result.user);
        }
        return result;
      });
  }

  register({ email, password }: LoginData): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then(result => {
        if (result.user) {
          this.user$.next(result.user);
        }
        return result;
      })
      .catch(e => {
        this.handleError(e);
        return null;
      });
  }

  deleteAccount(user: User): Promise<void> {
    return deleteUser(user).then(result => {
      this.user$.next(null);
    });
  }

  logout(): Promise<void> {
    return signOut(this.auth).then(_ => this.user$.next(null));
  }

  handleError(e: any): void {
    console.log(e.message);
    switch (e.code) {
      case FirebaseErrorCodes.Auth.emailAlreadyExists:
        break;
    }
  }
}
