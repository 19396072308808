import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {filter, tap} from 'rxjs/operators';
import {LoginData} from '../../interfaces/login-data.interface';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() formData: EventEmitter<LoginData> = new EventEmitter();
  @Output() formValid: EventEmitter<boolean> = new EventEmitter();
  @Input('resetForm') resetForm$: Subject<void>;

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      register: [],
    });

    this.form.valueChanges.pipe(
      filter(value => this.form.valid),
      tap(value => this.formData.next(value))
    ).subscribe();

    this.form.valueChanges.pipe(
      tap(_ => this.formValid.next(this.form.valid))
    ).subscribe();

    if(this.resetForm$) {
      this.resetForm$.subscribe(_ => this.form.reset());
    }
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get register(): AbstractControl {
    return this.form.get('register');
  }

  onSubmit(): void {
    this.formData.emit(this.form.value);
  }

}
