import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {combineLatest, Observable} from 'rxjs';
import {debounceTime, filter, first, map, shareReplay, startWith, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {MapService} from '../../services/map.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {City} from '../../models/city.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {latLng, LatLng} from 'leaflet';
import {MatSidenav} from '@angular/material/sidenav';
import {MatExpansionPanel} from '@angular/material/expansion';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {County} from '../../models/county.model';
import {MatDialog} from '@angular/material/dialog';
import {InfoDialogComponent} from '../info-dialog/info-dialog.component';
import {LoginDialogComponent} from '../../features/auth/components/login-dialog/login-dialog.component';
import {AuthService} from '../../features/auth/services/auth.service';
import {User} from '@angular/fire/auth';
import {BookmarkedParcelDialogComponent} from '../bookmarked-parcel-dialog/bookmarked-parcel-dialog.component';
import {environment} from '../../../environments/environment';
import * as L from 'leaflet';
import {Bookmark} from '../../models/bookmark.model';
import {ParcelInfo} from '../../models/parcel-info.model';
import {ApiService} from '../../services/api.service';
import {DashboardService} from '../../services/dashboard.service';
import {MatTabGroup} from '@angular/material/tabs';
import {Filters} from '../../models/filters.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild('drawerRight') drawerRight: MatSidenav;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  // @ViewChild('dvfChart') dvfChartElementRef: ElementRef;
  @ViewChild('parcelInfoPanel', {static: true}) parcelInfoPanel: MatExpansionPanel;

  cities: City[] = [];
  counties: County[] = [
    {
      code: 22,
      name: 'Côtes-d\'Armor',
    },
    {
      code: 29,
      name: 'Finistère',
    },
    {
      code: 35,
      name: 'Ille-et-Vilaine'
    },
    {
      code: 44,
      name: 'Loire-Atlantique'
    },
    {
      code: 56,
      name: 'Morbihan'
    },
    {
      code: 69,
      name: 'Rhône'
    }
  ].sort((a, b) => a.name.localeCompare(b.name));
  currentCity: City;
  currentCounty: County;
  filteredOptions: Observable<City[]>;
  dvfYears: number[] = [2014, 2015, 2016, 2017, 2018, 2019, 2020];
  today = new Date();
  month = this.today.getMonth();
  year = this.today.getFullYear();
  startDate = new Date(2014, 1, 1);
  endDate = new Date();
  parcelInfo$ = this.dashboardService.parcelInfo$;
  parcelInfo: ParcelInfo;

  aerial = false;
  mapThumbnailSrc = [
    'assets/img/map.png',
    'assets/img/aerial.png'
  ];

  form: UntypedFormGroup = new UntypedFormGroup({
    minArea: new UntypedFormControl(null),
    maxArea: new UntypedFormControl(null),
    county: new UntypedFormControl(this.counties.find(c => c.code === 35)),
    city: new UntypedFormControl(),
    dvfEnable: new UntypedFormControl(true),
    dvfAll: new UntypedFormControl(),
    dvfYears: new UntypedFormControl([this.dvfYears[this.dvfYears.length - 1]]),
    dvfStart: new UntypedFormControl(new Date(this.year - 1, this.month, 1)),
    dvfEnd: new UntypedFormControl(new Date(this.year, this.month, 1))
  });


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  user$ = this.authService.user$;
  bookmarks$ = this.dashboardService.bookmarks$;
  bookmarks: Bookmark[];

  constructor(private breakpointObserver: BreakpointObserver,
              private http: HttpClient,
              private authService: AuthService,
              public mapService: MapService,
              private api: ApiService,
              private dashboardService: DashboardService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {

    /** Init currentCounty value **/
    this.currentCounty = this.form.controls.county.value;

    this.filteredOptions = this.form.controls.city.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
        tap(a => {
          if (typeof this.form.controls.city.value !== 'string') {
            this.currentCity = this.form.controls.city.value;
          }
        }),
        map(value => typeof value === 'string' ? value : value.name),
        switchMap(value => {
          let params = new HttpParams();
          value ? params = params.append('nom', value) : params = params;
          params = params.append('codeDepartement', this.form.controls.county.value.code);
          params = params.append('fields', 'centre,contour');
          return this.http.get('https://geo.api.gouv.fr/communes', {params}).pipe(
            // filter((data: Array<any>) => data.length > 0),
            map((data: Array<any>) => {
                this.cities = data.map(c => {
                  return {
                    name: c.nom,
                    insee_code: c.code,
                    county: this.form.controls.county.value,
                    contour: c.contour.coordinates.map(arr => {
                      return arr.map(lngLat => {
                        return [lngLat[1], lngLat[0]];
                      });
                    }),
                    center: c.centre.coordinates ? new LatLng(c.centre.coordinates[1], c.centre.coordinates[0]) : null
                  } as City;
                }).sort((a, b) => a.name.localeCompare(b.name)) as Array<City>;
                return value ? this._filter(value) : this.cities.slice();
              }
            ),
          );
        }),
      );

    this.form.valueChanges.pipe(
      filter(filters => typeof filters.city !== 'string'),
      debounceTime(250),
      // tap(a => console.log(a)),
      tap(form => this.dashboardService.setFilters(form as Filters))
    ).subscribe();

    this.dashboardService.parcelInfo$.pipe(
      tap(parcelInfo => {
        this.parcelInfo = parcelInfo;
        if (parcelInfo) {

          /**Check existence of parcel info panel tab in accordion**/
          if (this.parcelInfoPanel) {
            this.parcelInfoPanel.disabled = false;
            this.parcelInfoPanel.open();
          }

          if (parcelInfo.dvf) {
            //this.initChart();
          }
        } else {
          if (this.parcelInfoPanel) {
            this.parcelInfoPanel.disabled = true;
            if (this.parcelInfoPanel._getExpandedState() === 'expanded') {
              this.parcelInfoPanel.close();
            }
          }

        }
      })
    ).subscribe();

    this.mapService.clearFilters$.subscribe(() => this.clearAll(true));
  }

  ngAfterViewInit(): void {
    this.dashboardService.refreshBookmarks();
    this.dashboardService.selectedTabIndex$.pipe(
      tap(selectedTabIndex => {
        if (this.tabGroup) {
          this.tabGroup.selectedIndex = selectedTabIndex;
        }
      })
    ).subscribe();
    this.bookmarks$.pipe(
      tap(bookmarks => {
        this.bookmarks = bookmarks;
        if (bookmarks.length === 0) {
          if (this.tabGroup && this.tabGroup.selectedIndex === 2) {
            if (this.parcelInfo) {
              this.tabGroup.selectedIndex = 1;
            } else {
              this.tabGroup.selectedIndex = 0;
            }
          }
        }
      })
    ).subscribe();
    this.form.controls.dvfEnable.valueChanges.pipe(
      // debounceTime(150),
      tap(value => {
        if (!value) {
          this.form.controls.dvfAll.patchValue(false);
        }
      })
    ).subscribe();

    this.form.controls.dvfAll.valueChanges.pipe(
      debounceTime(150),
      tap(value => {
        if (value) {
          this.form.controls.maxArea.patchValue(null);
          this.form.controls.minArea.patchValue(null);
          this.form.controls.maxArea.disable();
          this.form.controls.minArea.disable();
        } else {
          this.form.controls.maxArea.enable();
          this.form.controls.minArea.enable();
        }
      })
    ).subscribe();

    this.form.controls.county.valueChanges.pipe(
      debounceTime(150),
      tap((county: County) => {
        if (this.currentCounty && county !== this.currentCounty) {
          this.form.controls.city.patchValue('');
        }
        if (county.code === 35 || county.code === 56 || county.code === 69 || county.code === 44) {
          this.form.controls.dvfEnable.enable();
          this.form.controls.dvfEnable.patchValue(true);
        } else {
          this.form.controls.dvfEnable.patchValue(false);
          this.form.controls.dvfEnable.disable();
        }
        this.currentCounty = county;
      })
    ).subscribe();
  }

  toggleAerial(): void {
    this.aerial = !this.aerial;
    this.mapService.aerial$.next(this.aerial);
  }

  clearAll(dontEmit?: boolean): void {
    // this.drawerRight.close();
    const currentCity = this.form.controls.city.value;
    const currentCounty = this.form.controls.county.value;
    this.form.reset({
      city: currentCity ? currentCity : this.cities[0],
      county: currentCounty ? currentCounty : this.counties.find(c => c.code === 35),
      //city: currentCity,
      dvfEnable: true,
      dvfStart: new Date(this.year - 1, this.month, 1),
      dvfEnd: new Date(this.year, this.month, 1)
    });
    if (!dontEmit) {
      this.mapService.clearMap();
    }
    this.dashboardService.parcelInfo$.next(null);
  }

  // openInGoogleMaps(coords: LatLng): void {
  //   const url = `https://maps.google.com/?q=${coords.lat},${coords.lng}`;
  //   window.open(url, '_blank');
  // }

  updateCity(): void {
    if (typeof this.form.controls.city.value === 'string') {
      if (!this.currentCity) {
        this.form.controls.city.setValue(this.cities[0]);
      } else {
        this.form.controls.city.setValue(this.currentCity);
      }
    }
  }

  displayFn(city: City): string {
    return city && city.name  ? city.name : '';
  }

  private _filter(name: string): City[] {
    const filterValue = name.toLowerCase();
    return this.cities.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private find(array: any[], searchString: string): any {
    const str = searchString.split(' ');
    return array.filter(item => {
      return str.every(el => {
        return item.content.indexOf(el) > -1;
      });
    });
  }

  openInfoDialog(): void {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      width: '60%',
      maxHeight: '60%'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      maxWidth : '90%',
      maxHeight: '60%'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openBookmarkedParcel(e: Event, bookmark: Bookmark): void {
    e.stopImmediatePropagation();
    this.mapService.loadParcel(bookmark.parcelId);
    const dialogRef = this.dialog.open(BookmarkedParcelDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  focusParcel(e: Event, bookmark: Bookmark): void {
    e.stopImmediatePropagation();
    this.mapService.loadParcel(bookmark.parcelId);
    // this.http.get(`${environment.apiUrl}/parcels/${bookmark.parcelId}`).pipe().subscribe(e => console.log(e));
    // console.log(bookmark);
  }

  bookmarkParcel(parcelId: string, address: string, e?: Event): void {
    this.api.toggleBookmark(parcelId, address).subscribe(_ => this.dashboardService.refreshBookmarks());
  }


  // bookmarkParcel(parcelId: string, address: string, e?: Event): void {
  //   this.http.post(`${environment.apiUrl}/parcels/bookmark/${parcelId}/toggle`, {address}).pipe(
  //     tap((payload: any) => {
  //       console.log(payload);
  //     }),
  //     switchMap(_ => this.loadUserBookmarks()),
  //   ).subscribe();
  //   // this.parcelInfo$.pipe(
  //   //   switchMap(parcelInfo => {
  //   //     return this.http.post(`${environment.apiUrl}/parcels/bookmark/${parcelInfo.properties.id}/toggle`, {address: parcelInfo.address}).pipe(
  //   //       tap((payload: any) => {
  //   //         console.log(payload);
  //   //       })
  //   //     );
  //   //   }),
  //   // ).subscribe(() => this.loadUserBookmarks());
  // }
}
