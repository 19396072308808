import * as L from 'leaflet';

export const TILE_PROVIDERS = {
  map: L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    minZoom: 3,
    opacity: 1,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  }),

  geoportailFranceOrthos:  L.tileLayer('https://wxs.ign.fr/choisirgeoportail/geoportail/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}', {
    attribution: '<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>',
    bounds: [[-75, -180], [81, 180]],
    minZoom: 3,
    maxZoom: 19,
  }),

  rennesMetroSig:  L.tileLayer('https://public.sig.rennesmetropole.fr/geowebcache/service/tms/1.0.0/raster%3Aortho2020@EPSG%3A3857@jpeg/{z}/{x}/{-y}.jpg', {
    attribution: '<a target="_blank" href="https://www.rennesmetropole.fr/">source : Ortho Express 2020 - IGNF</a>',
    minZoom: 3,
    maxZoom: 19,
  }),

  cartoDBPositronOnlyLabels: L.tileLayer('https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}{r}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 19
  }),
};
