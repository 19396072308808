import { Injectable } from '@angular/core';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {AuthService} from '../features/auth/services/auth.service';
import {environment} from '../../environments/environment';
import {User} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  user: User;
  constructor(private authService: AuthService) {
    this.authService.user$.subscribe(user => this.user = user);
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.apiUrl)) {
      if (this.user) {
        // @ts-ignore
        const token = this.user.accessToken;
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        return next.handle(request).pipe(
          catchError((err) => {
            console.log(err);
            if (err.status === 401) {
              //this.authService.logout();
            }
            const error = err.error.message || err.statusText;
            return throwError(error);
          })
        );

      } else {
        return next.handle(request);
      }
    } else {
      return next.handle(request);
    }

  }
}
