<!--<div class="thumbnail" *ngIf="user$ | async">-->
<!--  <button mat-icon-button aria-label="Ajouter en favoris"-->
<!--          [color]="(parcelInfo.properties.id | parcelBookmarked : bookmarks) ? 'accent' : 'primary'"-->
<!--          (click)="bookmarkParcel(parcelInfo.properties.id, parcelInfo.address.label)">-->
<!--    <mat-icon>favorite</mat-icon>-->
<!--  </button>-->
<!--</div>-->
<img alt="Street view"
     class="streetview"
     [src]="'https://maps.googleapis.com/maps/api/streetview?size=299x299&location='+ parcelInfo.address.label + '&key=AIzaSyDhz3kZSJ8fXne4CIwk_-nGvH3XQ14PIzg'"/>
<span class="row">
  <span class="spacer"></span>
    <button mat-icon-button aria-label="Ajouter en favoris"
            matTooltip="Ajouter en favoris"
            [color]="(parcelInfo.properties.id | parcelBookmarked : bookmarks) ? 'accent' : 'primary'"
            (click)="bookmarkParcel(parcelInfo.properties.id, parcelInfo.address.label)">
  <mat-icon>favorite</mat-icon>
</button>
  <button mat-icon-button aria-label="Voir sur Google Maps"
          matTooltip="Voir sur Google Maps"
          (click)="openInGoogleMaps(parcelInfo.center)">
  <mat-icon class="material-icons-outlined">map</mat-icon>
  </button>
  <!--  <span class="mat-small link" (click)="openInGoogleMaps(parcelInfo.center)">Voir sur Google Maps</span>-->
</span>
<mat-list>
  <mat-list-item>
    <mat-icon matListIcon class="material-icons-outlined" matTooltip="Addresse">place</mat-icon>
    <span class="spacer"></span>
    <span class="last">{{ parcelInfo.address.name }}</span>
  </mat-list-item>
  <mat-list-item>
    <!--              <b>Numéro</b>-->
    <mat-icon matListIcon class="material-icons-outlined" matTooltip="Numéro de parcelle">tag</mat-icon>
    <span class="spacer"></span>
    {{ parcelInfo.properties.prefixe }} {{ parcelInfo.properties.section }} {{ parcelInfo.properties.numero}}
  </mat-list-item>
  <mat-list-item>
    <!--              <b>Contenance</b>-->
    <mat-icon matListIcon class="material-icons-outlined" matTooltip="Contenance">texture</mat-icon>
    <span class="spacer"></span>{{parcelInfo.properties.contenance}} m<sup>2</sup></mat-list-item>
  <mat-list-item>
    <!--<b>Arpent&eacute;</b>-->
    <mat-icon matListIcon class="material-icons-outlined" matTooltip="Arpenté">terrain</mat-icon>
    <span class="spacer"></span>
    <mat-icon>{{parcelInfo.properties.arpente ? 'done' : 'clear'}}</mat-icon>
  </mat-list-item>
  <mat-list-item>
    <!--              <b>Mise à jour</b>-->
    <mat-icon matListIcon class="material-icons-outlined" matTooltip="Mise à jour">update</mat-icon>
    <span class="spacer"></span>{{parcelInfo.properties.updated | date:'shortDate'}}</mat-list-item>
  <ng-container *ngIf="parcelInfo.dvf">
    <mat-divider></mat-divider>
    <ng-container [ngTemplateOutlet]="(parcelInfo.dvf | isArray) ? dvfArray : dvfTpl"
                  [ngTemplateOutletContext]="{dvf: parcelInfo.dvf}"
    ></ng-container>
    <ng-template #dvfArray let-dvf="dvf">
      <ng-container *ngFor="let d of dvf"
                    [ngTemplateOutlet]="dvfTpl"
                    [ngTemplateOutletContext]="{dvf: d}"></ng-container>
    </ng-template>
    <ng-template #dvfTpl let-dvf="dvf">
      <mat-list-item>
        <div matLine class="row"><b>{{dvf.type_local}} T{{dvf.nombre_pieces_principales}}</b><span
          class="spacer"></span>{{dvf.surface_reelle_bati}} m<sup>2</sup></div>
        <div matLine class="row">{{dvf.valeur_fonciere | dvfValue}} €<span
          class="spacer"></span>{{dvf | dvfSquareMeterValue}} €/m<sup>2</sup></div>
        <div matLine class="row"><span class="spacer"></span><span
          class="mat-small"> {{dvf.date_mutation | date:'shortDate'}} </span></div>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-template>
  </ng-container>
</mat-list>
