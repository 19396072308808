export const environment = {
  firebase: {
    projectId: 'codastre-2ccca',
    appId: '1:120734549680:web:316cab95b7ce789e6ddd75',
    storageBucket: 'codastre-2ccca.appspot.com',
    apiKey: 'AIzaSyBR3DKSIJr4Ppz_8DkWTmE-cNMIfSIEU-c',
    authDomain: 'codastre-2ccca.firebaseapp.com',
    messagingSenderId: '120734549680',
    measurementId: 'G-WC13N5T17Z',
  },
  production: true,
  apiUrl: 'https://parcelle.info/api'
};
