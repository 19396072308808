import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import {LoginFormComponent} from './components/login-form/login-form.component';
import {MaterialModule} from '../../material/material.module';
import {ReactiveFormsModule} from '@angular/forms';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { RegisterFormComponent } from './components/register-form/register-form.component';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../../../environments/environment';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {PERSISTENCE} from '@angular/fire/compat/auth';

/*https://betterprogramming.pub/angular-13-firebase-authentication-tutorial-with-angularfire-7-23dc8cee42c4*/
@NgModule({
  declarations: [LoginFormComponent, LoginDialogComponent, RegisterFormComponent],
  exports: [
    LoginFormComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    MaterialModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
  ],
  providers: []
})
export class AuthModule { }
