import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material/material.module';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MapComponent} from './components/map/map.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IsArrayPipe} from './pipes/is-array.pipe';
import {DvfSquareMeterValuePipe} from './pipes/dvf-square-meter-value.pipe';
import {DvfValuePipe} from './pipes/dvf-value.pipe';
import localeFr from '@angular/common/locales/fr';
import {registerLocaleData} from '@angular/common';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import {AuthModule} from './features/auth/auth.module';
import { BookmarkedParcelDialogComponent } from './components/bookmarked-parcel-dialog/bookmarked-parcel-dialog.component';
import { ParcelPriceChartComponent } from './components/parcel-price-chart/parcel-price-chart.component';
import {NgChartsModule} from 'ng2-charts';
import {TokenInterceptorService} from './interceptors/token-interceptor.service';
import { ParcelBookmarkedPipe } from './pipes/parcel-bookmarked.pipe';
import { ParcelInfoComponent } from './components/parcel-info/parcel-info.component';

registerLocaleData(localeFr);
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MapComponent,
    IsArrayPipe,
    DvfSquareMeterValuePipe,
    DvfValuePipe,
    InfoDialogComponent,
    BookmarkedParcelDialogComponent,
    ParcelPriceChartComponent,
    ParcelBookmarkedPipe,
    ParcelInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    AuthModule,
    NgChartsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-FR'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
