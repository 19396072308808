import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {first, tap} from 'rxjs/operators';

@Component({
  selector: 'app-parcel-price-chart',
  templateUrl: './parcel-price-chart.component.html',
  styleUrls: ['./parcel-price-chart.component.scss']
})
export class ParcelPriceChartComponent implements OnInit, AfterViewInit {

  today = new Date();
  yesterday = new Date(this.today.getFullYear() - 1, this.today.getMonth(), this.today.getDay());

  parcelPriceFormGroup: UntypedFormGroup;
  data;
  empty: boolean;

  // @ts-ignore
  // @ts-ignore
  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        borderColor: '#3F51B5',
        backgroundColor: 'rgba(63, 81, 181, 0.2)',
        tension: 0.5,
      },
      point: {
        borderColor: '#FFF',
        backgroundColor: 'rgba(63, 81, 181, 0.2)',
      }
    },
    // scales: {
    //   // We use this empty structure as a placeholder for dynamic theming.
    //   x: {},
    //   'y-axis-0':
    //     {
    //       position: 'left',
    //     },
    //   'y-axis-1': {
    //     position: 'right',
    //     grid: {
    //       color: 'rgba(255,0,0,0.3)',
    //     },
    //     ticks: {
    //       color: 'red'
    //     }
    //   }
    // },


    plugins: {
      legend: { display: false },
      // @ts-ignore
      annotation: {
        // annotations: [
        //   {
        //     type: 'line',
        //     scaleID: 'x',
        //     value: 'March',
        //     borderColor: 'orange',
        //     borderWidth: 2,
        //     label: {
        //       position: 'center',
        //       enabled: true,
        //       color: 'orange',
        //       content: 'LineAnno',
        //       font: {
        //         weight: 'bold'
        //       }
        //     }
        //   },
        // ],
      }
    }
  };

  public lineChartType: ChartType = 'line';

  @Input() parcelId: string;
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  // events
  public chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  }

  public chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    //  console.log(event, active);
  }

  constructor(private api: ApiService) {
    this.parcelPriceFormGroup = new UntypedFormGroup({
      priceCtrl: new UntypedFormControl(null, [Validators.required]),
      dateCtrl: new UntypedFormControl(new Date(), [Validators.required]),
    });
  }

  ngOnInit(): void {
    // this.data.datasets[0].data.forEach(d => {
    //   this.chart.labels.push(d.x);
    // });
  }

  ngAfterViewInit(): void {
    this.refreshParcelPrices();
  }

  refreshParcelPrices(): void {
    this.api.getPrices(this.parcelId).pipe(
      first(),
      tap(prices => {
        this.empty = prices.length === 0;
        const labels = prices.sort((a, b) => a.created.getTime() - b.created.getTime()).map(p => p.created.toLocaleDateString());
        const data = prices.map(p => {
          return {
            x: p.created.toLocaleDateString(),
            y: p.value,
          };
        });
        this.data = {
          labels,
          datasets: [{
            label: 'Historique des prix',
            fill: true,
            data,
          }]
        };
      }),
    ).subscribe();
  }

  submitPrice(): void {
    this.api.setParcelPrice(this.parcelId, this.parcelPriceFormGroup.controls.priceCtrl.value, this.parcelPriceFormGroup.controls.dateCtrl.value).pipe()
      .subscribe(_ => {
        this.parcelPriceFormGroup.reset();
        this.refreshParcelPrices();
      });
  }


}
