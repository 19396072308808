<h1 mat-dialog-title>Mon compte</h1>
<div mat-dialog-content>
  <ng-container *ngIf="(user$ | async) as user; then userProfileTpl; else loginFormTpl"
                [ngTemplateOutlet]="userProfileTpl"
                [ngTemplateOutletContext] ="{value: user}"
  ></ng-container>
  <ng-template #loginFormTpl >
    <app-login-form [resetForm]="resetForm$"
                    (formData)="getFormData($event)"
                    (formValid)="checkFormValidity($event)"></app-login-form>
  </ng-template>
  <ng-template #userProfileTpl let-user>
    <p>Vous êtes connecté(e) en tant que {{user.email}}</p>
    <button mat-flat-button color="primary" (click)="logout()">Déconnexion</button>
    <button mat-flat-button color="warn" (click)="deleteAccount()">Supprimer le compte</button>
  </ng-template>
</div>
<div mat-dialog-actions align="end">
  <button mat-button *ngIf="!(user$ | async)" (click)="onNoClick()">Annuler</button>
  <button mat-button (click)="onSubmit()" [disabled]="!formValid && !(user$ | async)">Ok</button>
</div>
