<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav mat-elevation-z16" fixedInViewport
               [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
               [mode]="(isHandset$ | async) ? 'over' : 'side'"
               [opened]="(isHandset$ | async) === false">
    <mat-toolbar>
      <span>parcelle.info</span>
      <span class="spacer"></span>
      <button mat-icon-button (click)="clearAll()" matTooltip="Reset filtres">
        <mat-icon>clear_all</mat-icon>
      </button>
      <button mat-icon-button (click)="openInfoDialog()" matTooltip="Infos">
        <mat-icon class="material-icons-outlined">info</mat-icon>
      </button>
      <button mat-icon-button (click)="openLoginDialog()" matTooltip="Mon compte">
        <mat-icon [ngClass]='{"material-icons-outlined": !(user$ | async)}'>face</mat-icon>
      </button>
    </mat-toolbar>
    <ng-container *ngIf="!(user$ | async); then simpleTpl; else tabsTpl"></ng-container>
    <ng-template #simpleTpl>
      <ng-template [ngTemplateOutlet]="accordionTpl"></ng-template>
    </ng-template>
    <ng-template #tabsTpl>
      <mat-tab-group align="start" #tabGroup>
        <mat-tab label="Chercher"><ng-template [ngTemplateOutlet]="accordionTpl"></ng-template></mat-tab>
        <mat-tab label="Parcelle" [disabled]="!(parcelInfo$ | async)">
          <app-parcel-info *ngIf="(parcelInfo$ | async) as parcelInfo" [parcelInfo]="parcelInfo"></app-parcel-info>
          <!--          <ng-container *ngIf="(parcelInfo$ | async) as parcelInfo; then parcelInfoTpl"-->
          <!--                        [ngTemplateOutlet]="parcelInfoTpl"-->
          <!--                        [ngTemplateOutletContext]="{parcelInfo: parcelInfo}"></ng-container>-->
        </mat-tab>
        <mat-tab label="Favoris" [disabled]="(bookmarks$ | async).length === 0">
          <mat-action-list>
            <mat-list-item *ngFor="let bookmark of bookmarks$ | async">
              <span matLine class="mat-small" [matTooltip]="bookmark.label">{{ bookmark.label }}</span>
              <button mat-icon-button (click)="focusParcel($event, bookmark)">
                <mat-icon inline class="material-icons-outlined">place</mat-icon>
              </button>
              <button mat-icon-button (click)="openBookmarkedParcel($event, bookmark)">
                <mat-icon inline class="material-icons-outlined">notes</mat-icon>
              </button>
              <button mat-icon-button (click)="bookmarkParcel(bookmark.parcelId, bookmark.label, $event)">
                <mat-icon inline class="material-icons-outlined">close</mat-icon>
              </button>
            </mat-list-item>
          </mat-action-list>
        </mat-tab>
      </mat-tab-group>
    </ng-template>
    <ng-template #accordionTpl>
      <mat-accordion [formGroup]="form" displayMode="flat">
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Lieu
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-grid-list cols="2" rowHeight="70px">
            <mat-grid-tile colspan="2" rowspan="1">
              <mat-form-field appearance="fill">
                <mat-label>Département</mat-label>
                <mat-select formControlName="county">
                  <mat-option *ngFor="let county of counties" [value]="county">{{county.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="2" rowspan="1">
              <mat-form-field appearance="fill">
                <mat-label>Ville</mat-label>
                <input type="text" matInput formControlName="city" [matAutocomplete]="auto" required>
                <mat-autocomplete #auto="matAutocomplete"
                                  (closed)="updateCity()"
                                  [displayWith]="displayFn">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>

        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Filtres
            </mat-panel-title>
            <!--          <mat-panel-description>-->
            <!--            This is a summary of the content-->
            <!--          </mat-panel-description>-->
          </mat-expansion-panel-header>
          <mat-grid-list cols="2" rowHeight="70px">
            <!--          <mat-grid-tile colspan="2" rowspan="1">-->
            <!--            <mat-form-field appearance="fill">-->
            <!--              <mat-label>Département</mat-label>-->
            <!--              <mat-select formControlName="county">-->
            <!--                <mat-option *ngFor="let county of counties" [value]="county">{{county.name}}</mat-option>-->
            <!--              </mat-select>-->
            <!--            </mat-form-field>-->
            <!--          </mat-grid-tile>-->
            <!--          <mat-grid-tile colspan="2" rowspan="1">-->
            <!--            &lt;!&ndash;              <mat-form-field appearance="fill">&ndash;&gt;-->
            <!--            &lt;!&ndash;                <mat-label>Ville</mat-label>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <mat-select formControlName="city" required>&ndash;&gt;-->
            <!--            &lt;!&ndash;                  <mat-option *ngFor="let city of cities" [value]="city">&ndash;&gt;-->
            <!--            &lt;!&ndash;                    {{city.name}}&ndash;&gt;-->
            <!--            &lt;!&ndash;                  </mat-option>&ndash;&gt;-->
            <!--            &lt;!&ndash;                </mat-select>&ndash;&gt;-->
            <!--            &lt;!&ndash;                <mat-error *ngIf="form.controls.city.hasError('required')">Merci de choisir une ville</mat-error>&ndash;&gt;-->
            <!--            &lt;!&ndash;              </mat-form-field>&ndash;&gt;-->
            <!--            <mat-form-field appearance="fill">-->
            <!--              <mat-label>Ville</mat-label>-->
            <!--              <input type="text" matInput formControlName="city" [matAutocomplete]="auto" required>-->
            <!--              <mat-autocomplete #auto="matAutocomplete"-->
            <!--                                (closed)="updateCity()"-->
            <!--                                [displayWith]="displayFn">-->
            <!--                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">-->
            <!--                  {{option.name}}-->
            <!--                </mat-option>-->
            <!--              </mat-autocomplete>-->
            <!--            </mat-form-field>-->
            <!--          </mat-grid-tile>-->
            <!-- Filters more -->
            <!-- -->
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-form-field appearance="fill">
                <mat-label>Min</mat-label>
                <input matInput formControlName="minArea" autocomplete="off">
                <!--              <mat-hint>Surface en m<sup>2</sup></mat-hint>-->
                <!--            <span matSuffix>m<sup>2</sup></span>-->
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-form-field appearance="fill">
                <mat-label>Max</mat-label>
                <input matInput formControlName="maxArea" autocomplete="off">
                <!--              <mat-hint>Surface en m<sup>2</sup></mat-hint>-->
                <!--            <span matSuffix>m<sup>2</sup></span>-->
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-slide-toggle formControlName="dvfEnable">DVF</mat-slide-toggle>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="form.controls.dvfEnable.value" colspan="1" rowspan="1">
              <mat-checkbox formControlName="dvfAll">Voir tout</mat-checkbox>
            </mat-grid-tile>
            <!--        <mat-grid-tile *ngIf="form.controls.dvfEnable.value" colspan="2" rowspan="1">-->
            <!--          <mat-form-field appearance="fill">-->
            <!--            <mat-label>Année</mat-label>-->
            <!--            <mat-select formControlName="dvfYears" required multiple>-->
            <!--              <mat-option *ngFor="let year of dvfYears" [value]="year">-->
            <!--                {{year}}-->
            <!--              </mat-option>-->
            <!--            </mat-select>-->
            <!--            <mat-error *ngIf="form.controls.cityInseeCode.hasError('required')">Merci de choisir une ville</mat-error>-->
            <!--          </mat-form-field>-->
            <!--        </mat-grid-tile>-->
            <!--        <mat-grid-tile *ngIf="form.controls.dvfEnable.value" colspan="2" rowspan="1">-->
            <!--          <mat-form-field class="example-form-field" appearance="fill">-->
            <!--            <mat-label>First campaign</mat-label>-->
            <!--            <mat-date-range-input-->
            <!--              [formGroup]="form"-->
            <!--              [rangePicker]="campaignOnePicker">-->
            <!--              <input matStartDate placeholder="Début" formControlName="dvfStart">-->
            <!--              <input matEndDate placeholder="Fin" formControlName="dvfEnd">-->
            <!--            </mat-date-range-input>-->
            <!--            <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>-->
            <!--            <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>-->
            <!--          </mat-form-field>-->
            <!--        </mat-grid-tile>-->
            <ng-container *ngIf="form.controls.dvfEnable.value">
              <mat-grid-tile colspan="1" rowspan="1">
                <mat-form-field appearance="fill">
                  <mat-label>Début</mat-label>
                  <input matInput [matDatepicker]="pickerStart" formControlName="dvfStart" [min]="startDate"
                         [max]="endDate">
                  <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                  <mat-datepicker #pickerStart startView="multi-year"></mat-datepicker>
                </mat-form-field>
              </mat-grid-tile>
              <mat-grid-tile colspan="1" rowspan="1">
                <mat-form-field appearance="fill">
                  <mat-label>Fin</mat-label>
                  <input matInput [matDatepicker]="pickerEnd" formControlName="dvfEnd" [min]="startDate" [max]="endDate">
                  <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                  <mat-datepicker #pickerEnd></mat-datepicker>
                </mat-form-field>
              </mat-grid-tile>
            </ng-container>
          </mat-grid-list>
          <!--        <span class="row"><span class="spacer"></span><span class="mat-small link" (click)="clearAll()">Effacer les filtres</span></span>-->
        </mat-expansion-panel>
        <mat-expansion-panel #parcelInfoPanel
                             *ngIf="!(user$ | async)"
                             [class.hide]="!(parcelInfo$ | async)"
                             class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Parcelle
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-parcel-info *ngIf="(parcelInfo$ | async) as parcelInfo" [parcelInfo]="parcelInfo"></app-parcel-info>

          <!--          <ng-container *ngIf="(parcelInfo$ | async) as parcelInfo">-->
          <!--            <img alt="Street view"-->
          <!--                 [src]="'https://maps.googleapis.com/maps/api/streetview?size=251x251&location='+ parcelInfo.address.label + '&key=AIzaSyDhz3kZSJ8fXne4CIwk_-nGvH3XQ14PIzg'"/>-->
          <!--            &lt;!&ndash;          <img alt="Street view" [src]="'https://maps.googleapis.com/maps/api/streetview?size=207x207&location='+ parcelInfo.center.lat +',' + parcelInfo.center.lng +'&heading=151.78&pitch=-0.76&key=AIzaSyDhz3kZSJ8fXne4CIwk_-nGvH3XQ14PIzg'"/>&ndash;&gt;-->
          <!--            <span class="row"><span class="spacer"></span><span class="mat-small link"-->
          <!--                                                                (click)="openInGoogleMaps(parcelInfo.center)">Voir sur Google Maps</span></span>-->
          <!--            <mat-list>-->
          <!--              <mat-list-item>-->
          <!--                <mat-icon matListIcon class="material-icons-outlined" matTooltip="Addresse">place</mat-icon>-->
          <!--                <span class="spacer"></span>-->
          <!--                <span class="last">{{ parcelInfo.address.name }}</span>-->
          <!--              </mat-list-item>-->
          <!--              <mat-list-item>-->
          <!--                &lt;!&ndash;              <b>Numéro</b>&ndash;&gt;-->
          <!--                <mat-icon matListIcon class="material-icons-outlined" matTooltip="Numéro de parcelle">tag</mat-icon>-->
          <!--                <span class="spacer"></span>-->
          <!--                {{ parcelInfo.properties.prefixe }} {{ parcelInfo.properties.section }} {{ parcelInfo.properties.numero}}-->
          <!--              </mat-list-item>-->
          <!--              <mat-list-item>-->
          <!--                &lt;!&ndash;              <b>Contenance</b>&ndash;&gt;-->
          <!--                <mat-icon matListIcon class="material-icons-outlined" matTooltip="Contenance">texture</mat-icon>-->
          <!--                <span class="spacer"></span>{{parcelInfo.properties.contenance}} m<sup>2</sup></mat-list-item>-->
          <!--              <mat-list-item>-->
          <!--                &lt;!&ndash;<b>Arpent&eacute;</b>&ndash;&gt;-->
          <!--                <mat-icon matListIcon class="material-icons-outlined" matTooltip="Arpenté">terrain</mat-icon>-->
          <!--                <span class="spacer"></span>-->
          <!--                <mat-icon>{{parcelInfo.properties.arpente ? 'done' : 'clear'}}</mat-icon>-->
          <!--              </mat-list-item>-->
          <!--              <mat-list-item>-->
          <!--                &lt;!&ndash;              <b>Mise à jour</b>&ndash;&gt;-->
          <!--                <mat-icon matListIcon class="material-icons-outlined" matTooltip="Mise à jour">update</mat-icon>-->
          <!--                <span class="spacer"></span>{{parcelInfo.properties.updated | date:'shortDate'}}</mat-list-item>-->
          <!--              <ng-container *ngIf="parcelInfo.dvf">-->
          <!--                <mat-divider></mat-divider>-->
          <!--                <ng-container [ngTemplateOutlet]="(parcelInfo.dvf | isArray) ? dvfArray : dvfTpl"-->
          <!--                              [ngTemplateOutletContext]="{dvf: parcelInfo.dvf}"-->
          <!--                ></ng-container>-->
          <!--                <ng-template #dvfArray let-dvf="dvf">-->
          <!--                  <ng-container *ngFor="let d of dvf"-->
          <!--                                [ngTemplateOutlet]="dvfTpl"-->
          <!--                                [ngTemplateOutletContext]="{dvf: d}"></ng-container>-->
          <!--                </ng-template>-->
          <!--                <ng-template #dvfTpl let-dvf="dvf">-->
          <!--                  <mat-list-item>-->
          <!--                    <div matLine class="row"><b>{{dvf.type_local}} T{{dvf.nombre_pieces_principales}}</b><span-->
          <!--                      class="spacer"></span>{{dvf.surface_reelle_bati}} m<sup>2</sup></div>-->
          <!--                    <div matLine class="row">{{dvf.valeur_fonciere | dvfValue}} €<span-->
          <!--                      class="spacer"></span>{{dvf | dvfSquareMeterValue}} €/m<sup>2</sup></div>-->
          <!--                    <div matLine class="row"><span class="spacer"></span><span-->
          <!--                      class="mat-small"> {{dvf.date_mutation | date:'shortDate'}} </span></div>-->
          <!--                  </mat-list-item>-->
          <!--                  <mat-divider></mat-divider>-->
          <!--                </ng-template>-->
          <!--              </ng-container>-->
          <!--            </mat-list>-->
          <!--          </ng-container>-->
        </mat-expansion-panel>
      </mat-accordion>
    </ng-template>
    <!--    <ng-template #parcelInfoTpl let-parcelInfo>-->
    <!--      <div class="thumbnail" [ngClass]="{'thumbnail-hover' : true}">-->
    <!--        <button mat-icon-button aria-label="Ajouter en favoris"-->
    <!--                [color]="(parcelInfo.properties.id | parcelBookmarked : bookmarks) ? 'accent' : 'primary'"-->
    <!--                (click)="bookmarkParcel(parcelInfo.properties.id, parcelInfo.address)">-->
    <!--          <mat-icon>favorite</mat-icon>-->
    <!--        </button>-->
    <!--      </div>-->
    <!--      <img alt="Street view"-->
    <!--           [ngClass]="{'streetview-small': !(user$ | async)}"-->
    <!--           [src]="'https://maps.googleapis.com/maps/api/streetview?size=299x299&location='+ parcelInfo.address.label + '&key=AIzaSyDhz3kZSJ8fXne4CIwk_-nGvH3XQ14PIzg'"/>-->
    <!--      <span class="row">-->
    <!--          <span class="spacer">-->
    <!--          </span><span class="mat-small link"-->
    <!--                       (click)="openInGoogleMaps(parcelInfo.center)">Voir sur Google Maps</span>-->
    <!--        </span>-->
    <!--      <mat-list>-->
    <!--        <mat-list-item>-->
    <!--          <mat-icon matListIcon class="material-icons-outlined" matTooltip="Addresse">place</mat-icon>-->
    <!--          <span class="spacer"></span>-->
    <!--          <span class="last">{{ parcelInfo.address.name }}</span>-->
    <!--        </mat-list-item>-->
    <!--        <mat-list-item>-->
    <!--          &lt;!&ndash;              <b>Numéro</b>&ndash;&gt;-->
    <!--          <mat-icon matListIcon class="material-icons-outlined" matTooltip="Numéro de parcelle">tag</mat-icon>-->
    <!--          <span class="spacer"></span>-->
    <!--          {{ parcelInfo.properties.prefixe }} {{ parcelInfo.properties.section }} {{ parcelInfo.properties.numero}}-->
    <!--        </mat-list-item>-->
    <!--        <mat-list-item>-->
    <!--          &lt;!&ndash;              <b>Contenance</b>&ndash;&gt;-->
    <!--          <mat-icon matListIcon class="material-icons-outlined" matTooltip="Contenance">texture</mat-icon>-->
    <!--          <span class="spacer"></span>{{parcelInfo.properties.contenance}} m<sup>2</sup></mat-list-item>-->
    <!--        <mat-list-item>-->
    <!--          &lt;!&ndash;<b>Arpent&eacute;</b>&ndash;&gt;-->
    <!--          <mat-icon matListIcon class="material-icons-outlined" matTooltip="Arpenté">terrain</mat-icon>-->
    <!--          <span class="spacer"></span>-->
    <!--          <mat-icon>{{parcelInfo.properties.arpente ? 'done' : 'clear'}}</mat-icon>-->
    <!--        </mat-list-item>-->
    <!--        <mat-list-item>-->
    <!--          &lt;!&ndash;              <b>Mise à jour</b>&ndash;&gt;-->
    <!--          <mat-icon matListIcon class="material-icons-outlined" matTooltip="Mise à jour">update</mat-icon>-->
    <!--          <span class="spacer"></span>{{parcelInfo.properties.updated | date:'shortDate'}}</mat-list-item>-->
    <!--        <ng-container *ngIf="parcelInfo.dvf">-->
    <!--          <mat-divider></mat-divider>-->
    <!--          <ng-container [ngTemplateOutlet]="(parcelInfo.dvf | isArray) ? dvfArray : dvfTpl"-->
    <!--                        [ngTemplateOutletContext]="{dvf: parcelInfo.dvf}"-->
    <!--          ></ng-container>-->
    <!--          <ng-template #dvfArray let-dvf="dvf">-->
    <!--            <ng-container *ngFor="let d of dvf"-->
    <!--                          [ngTemplateOutlet]="dvfTpl"-->
    <!--                          [ngTemplateOutletContext]="{dvf: d}"></ng-container>-->
    <!--          </ng-template>-->
    <!--          <ng-template #dvfTpl let-dvf="dvf">-->
    <!--            <mat-list-item>-->
    <!--              <div matLine class="row"><b>{{dvf.type_local}} T{{dvf.nombre_pieces_principales}}</b><span-->
    <!--                class="spacer"></span>{{dvf.surface_reelle_bati}} m<sup>2</sup></div>-->
    <!--              <div matLine class="row">{{dvf.valeur_fonciere | dvfValue}} €<span-->
    <!--                class="spacer"></span>{{dvf | dvfSquareMeterValue}} €/m<sup>2</sup></div>-->
    <!--              <div matLine class="row"><span class="spacer"></span><span-->
    <!--                class="mat-small"> {{dvf.date_mutation | date:'shortDate'}} </span></div>-->
    <!--            </mat-list-item>-->
    <!--            <mat-divider></mat-divider>-->
    <!--          </ng-template>-->
    <!--        </ng-container>-->
    <!--      </mat-list>-->
    <!--    </ng-template>-->
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        class="white"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <!--      <span>Codastre</span>-->
      <!--      <span class="spacer"></span>-->
      <!--      <button-->
      <!--        type="button"-->
      <!--        aria-label="Toggle right drawer"-->
      <!--        mat-icon-button-->
      <!--        (click)="drawerRight.toggle()"-->
      <!--        *ngIf="isHandset$ | async">-->
      <!--        <mat-icon aria-label="Side nav toggle icon">info</mat-icon>-->
      <!--      </button>-->
    </mat-toolbar>
    <!-- Add Content Here -->
    <app-map></app-map>
    <img [src]="aerial ? mapThumbnailSrc[0] : mapThumbnailSrc[1]" (click)="toggleAerial()" class="aerial-thumb"/>
  </mat-sidenav-content>
</mat-sidenav-container>
