import { Component, OnInit } from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';

@Component({
  selector: 'app-bookmarked-parcel-dialog',
  templateUrl: './bookmarked-parcel-dialog.component.html',
  styleUrls: ['./bookmarked-parcel-dialog.component.scss']
})
export class BookmarkedParcelDialogComponent implements OnInit {

  parcelInfo$ = this.dashboardService.parcelInfo$;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
  }

}
