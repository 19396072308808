<h1 mat-dialog-title>Infos</h1>
<div mat-dialog-content>
  <h3>Données</h3>
  <p>Cet outil utilise des données publiées sous <b>Licence Ouverte / Open Licence</b> par <b>Etalab</b> et mises à jour en Octobre 2021.</p>
  <h3>Bugs</h3>
  <p>En cas d'anomalie de fonctionnement, le plus simple est de recharger la page et recommencer.</p>
  <h3>DVF</h3>
  <p>Les données "Demandes de valeurs foncières" ne sont disponibles que pour les départements 35, 44, 56, 69.</p>
  <h3>Comptes utilisateurs</h3>
  <p>Il s'agit d'une feature bêta dont le <b>fonctionnement n'est pas garanti</b>.</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close] cdkFocusInitial>Ok</button>
</div>
