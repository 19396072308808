import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Bookmark} from '../models/bookmark.model';
import {AuthService} from '../features/auth/services/auth.service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Marker} from '../models/marker.model';
import {Price} from '../models/price.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,
              private authService: AuthService) { }

  getBookmarks(): Observable<Bookmark[]> {
    return this.authService.user$.pipe(
      filter(user => user && user.uid !== null),
      switchMap(user => this.http.get(`${environment.apiUrl}/bookmarks`).pipe(
        map((payload: any) => payload.data.bookmarks as Bookmark[]),
      ))
    );
  }

  getMarkers(): Observable<Marker[]> {
    return this.authService.user$.pipe(
      filter(user => user && user.uid !== null),
      switchMap(user => this.http.get(`${environment.apiUrl}/bookmarks/markers`).pipe(
        map((payload: any) => payload as Marker[]),
      ))
    );
  }

  toggleBookmark(parcelId: string, address: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/parcels/bookmark/${parcelId}/toggle`, {address}).pipe(
      tap((payload: any) => {
        //console.log(payload);
      }),
    );
  }

  getPrices(parcelId: string): Observable<Price[]> {
    return this.authService.user$.pipe(
      filter(user => user && user.uid !== null),
      switchMap(user => this.http.get(`${environment.apiUrl}/prices/${parcelId}`).pipe(
        map((payload: any) => payload.data.prices.map(p => {
          return {
            ...p,
            created: new Date(p.created)
          };
        }) as Price[]),
      ))
    );
  }


  setParcelPrice(parcelId: string, price: number, date: Date): Observable<any> {
    const dto = {
      created: date,
      price,
    };
    return this.http.post(`${environment.apiUrl}/parcels/price/${parcelId}`, dto).pipe(
      tap((payload: any) => {
        //console.log(payload);
      }),
    );
  }


}
