<h1 mat-dialog-title>Infos</h1>
<div mat-dialog-content *ngIf="parcelInfo$ | async as parcelInfo">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="material-icons-outlined mat-tab-icon">place</mat-icon>
        Parcelle
      </ng-template>
      <ng-template matTabContent>
        <app-parcel-info [parcelInfo]="parcelInfo"></app-parcel-info>
      </ng-template>
    </mat-tab>
    <mat-tab disabled>
      <ng-template mat-tab-label>
        <mat-icon class="mat-tab-icon">notes</mat-icon>
        Notes
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="mat-tab-icon">trending_up</mat-icon>
        Prix
      </ng-template>
      <ng-template matTabContent>
        <app-parcel-price-chart [parcelId]="parcelInfo.properties.id"></app-parcel-price-chart>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close] cdkFocusInitial>Ok</button>
</div>
