import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Filters} from '../models/filters.model';
import {ParcelInfo} from '../models/parcel-info.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {first, map, tap} from 'rxjs/operators';
import {LatLng} from 'leaflet';
import {City} from '../models/city.model';
import {Address} from '../models/address.model';
import {GeoJSONObject} from '@turf/helpers';
import {environment} from '../../environments/environment';
import * as L from 'leaflet';
import {Dvf} from '../models/dvf.model';
import {DashboardService} from './dashboard.service';
import {ApiService} from './api.service';
import {Marker} from '../models/marker.model';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  // filters$ = new BehaviorSubject<Filters>(null);
  // parcelInfo$ = new BehaviorSubject<ParcelInfo>(null);
  aerial$ = new Subject<boolean>();
  clearMap$ = new Subject<boolean>();
  clearFilters$ = new Subject<boolean>();
  loadParcel$ = new BehaviorSubject<{ geoJson: any, dvf: Dvf | Dvf[] }>(null);
  markers$ = new BehaviorSubject<Marker[]>([]);

  constructor(private http: HttpClient,
              private api: ApiService,
              private dashboardService: DashboardService) {}

  clearMap(): void {
    this.clearMap$.next(true);
  }

  setParcelInfo(parcelInfo: ParcelInfo): void {
    parcelInfo = {...parcelInfo};
    this.reverseGeocode(parcelInfo.center).pipe(
      first(),
      tap(address => {
        parcelInfo = {
          ...parcelInfo,
          address
        };
        this.dashboardService.parcelInfo$.next(parcelInfo);
      })
    ).subscribe();
  }

  reverseGeocode(latLng: LatLng): Observable<Address> {
    let params = new HttpParams();
    params = params.append('lon', latLng.lng);
    params = params.append('lat', latLng.lat);
    return this.http.get('https://api-adresse.data.gouv.fr/reverse', {params}).pipe(
      first(),
      map((data: any) => {
          const address = data.features[0].properties;
          return address ? address : null;
          // return data.features[0].properties as Address;
        }
      ),
    );
  }

  lookupParcel(latLng: LatLng, filters?: Filters): Observable<{ geoJson: any | any[], dvf: Dvf | Dvf[] }> {
    const params = new HttpParams()
      .set('lat', String(latLng.lat))
      .set('lng', String(latLng.lng))
      .set('dvf', String(filters.dvfEnable))
      .set('dvfAll', String(filters.dvfAll))
      .set('dvfMinDate', filters.dvfStart.toISOString())
      .set('dvfMaxDate', filters.dvfEnd.toISOString());

    return this.http.get(`${environment.apiUrl}/city/${filters.city.insee_code}/lookup`, {params}).pipe(
      map((payload: any) => {
        return {
          geoJson: payload.data.geoJson as any | any[],
          dvf: payload.data.dvf as Dvf | Dvf[]
        };
      }),
      // tap(p => console.log(p))
    );
  }

  loadParcels(filters: Filters): Observable<{ geoJson: any | any[], dvf: Dvf | Dvf[] }> {
    const params = new HttpParams()
      .set('min', String(filters.minArea))
      .set('max', filters.maxArea ? String(filters.maxArea) : String(filters.minArea))
      .set('dvf', String(filters.dvfEnable))
      .set('dvfAll', String(filters.dvfAll))
      .set('dvfMinDate', filters.dvfStart.toISOString())
      .set('dvfMaxDate', filters.dvfEnd.toISOString());

    return this.http.get(`${environment.apiUrl}/city/${filters.city.insee_code}`, {params}).pipe(
      map((payload: any) => {
        return {
          geoJson: payload.data.geoJson as any | any[],
          dvf: payload.data.dvf as Dvf | Dvf[]
        };
      }),
      // tap(p => console.log(p))
    );
  }

  loadParcel(parcelId: string): void {
    this.http.get(`${environment.apiUrl}/parcels/${parcelId}`).pipe(
      first(),
      map((payload: any) => payload.data as { geoJson: any, dvf: Dvf | Dvf[] }),
      tap(parcel => this.loadParcel$.next(parcel))
    ).subscribe();
  }

  getMarkers(): void {
    this.api.getMarkers().pipe(
      first(),
      tap(markers => this.markers$.next(markers))
    ).subscribe();
  }
}
