import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dvfValue'
})
export class DvfValuePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return String(value).split('').reverse().join('').replace(/([0-9]{3})/g, '$1 ').split('').reverse().join('');
  }

}
