<div class="flex">
<!--  <div class="flex-item">-->
<!--    <div style="display: block;">-->
<!--      <canvas baseChart width="400" height="400"-->
<!--              [data]="data"-->
<!--              [options]="lineChartOptions"-->
<!--              [type]="lineChartType"-->
<!--              (chartHover)="chartHovered($event)"-->
<!--              (chartClick)="chartClicked($event)"></canvas>-->
<!--    </div>-->
<!--  </div>-->
  <mat-accordion displayMode="flat" multi>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header class="mat-elevation-z0">
        <mat-panel-title>Historique des prix</mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="empty; then emptyTpl else canvasTpl"></ng-container>
      <ng-template #canvasTpl>      <canvas baseChart width="250" height="250"
                                            [data]="data"
                                            [options]="lineChartOptions"
                                            [type]="lineChartType"
                                            (chartHover)="chartHovered($event)"
                                            (chartClick)="chartClicked($event)"></canvas></ng-template>
      <ng-template #emptyTpl>
        <p>Il n'y a pas encore de données pour l'instant.</p>
        <p>Vous pouvez commencer à saisir des données dans l'onget ci-dessous.</p>
      </ng-template>

    </mat-expansion-panel>
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Ajouter un prix
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="column">
        <form [formGroup]="parcelPriceFormGroup">
          <mat-form-field appearance="fill" class="row-form-field">
            <mat-label>Prix</mat-label>
            <input matInput formControlName="priceCtrl" placeholder="Prix" type="number" min="0" autocomplete="off">
            <mat-icon matSuffix>euro</mat-icon>
          </mat-form-field>
          <mat-form-field appearance="fill" class="row-form-field">
            <mat-label>Choisir une date</mat-label>
            <input matInput  [max]="today" [min]="yesterday" [matDatepicker]="picker" formControlName="dateCtrl" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </form>
        <button mat-button aria-label="Ajouter le prix" (click)="submitPrice()" [disabled]="!parcelPriceFormGroup.valid">Ajouter</button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>
