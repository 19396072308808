import { Pipe, PipeTransform } from '@angular/core';
import {Bookmark} from '../models/bookmark.model';

@Pipe({
  name: 'parcelBookmarked'
})
export class ParcelBookmarkedPipe implements PipeTransform {

  transform(parcelId: string, bookmarks: Bookmark[]): boolean {
    if (bookmarks) {
      return !!bookmarks.find(b => b.parcelId === parcelId);
    } else {
      return null;
    }

  }

}
