import {Component, Input, OnInit} from '@angular/core';
import {ParcelInfo} from '../../models/parcel-info.model';
import {LatLng} from 'leaflet';
import {environment} from '../../../environments/environment';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Bookmark} from '../../models/bookmark.model';
import {AuthService} from '../../features/auth/services/auth.service';
import {ApiService} from '../../services/api.service';
import {DashboardService} from '../../services/dashboard.service';

@Component({
  selector: 'app-parcel-info',
  templateUrl: './parcel-info.component.html',
  styleUrls: ['./parcel-info.component.scss']
})
export class ParcelInfoComponent implements OnInit {

  @Input() parcelInfo: ParcelInfo;

  bookmarks$ = this.dashboardService.bookmarks$;
  bookmarks: Bookmark[];

  user$ = this.authService.user$;

  constructor(private http: HttpClient,
              private api: ApiService,
              private dashboardService: DashboardService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.bookmarks$.pipe(
      tap(bookmarks => this.bookmarks = bookmarks)
    ).subscribe();
  }

  openInGoogleMaps(coords: LatLng): void {
    const url = `https://maps.google.com/?q=${coords.lat},${coords.lng}`;
    window.open(url, '_blank');
  }

  bookmarkParcel(parcelId: string, address: string, e?: Event): void {
    //console.log(parcelId);
    this.api.toggleBookmark(parcelId, address).subscribe(_ => this.dashboardService.refreshBookmarks());
  }

}
