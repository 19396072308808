import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {LoginData} from '../../interfaces/login-data.interface';
import {UserCredential} from '@angular/fire/auth';
import {first, tap} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  formValid: boolean;
  loginData: LoginData;
  user$ = this.authService.user$;
  resetForm$ = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<LoginDialogComponent>,
              private readonly authService: AuthService,
              private readonly router: Router) { }

  ngOnInit(): void {
  }

  login(loginData: LoginData): void {
    this.authService
      .login(loginData)
      .then(userCredential => {
        console.log(userCredential);
        this.dialogRef.close();
      })
      .catch((e) => console.log(e.message));
  }

  register(data: LoginData): void {
    this.authService
      .register(data)
      .then(userCredential => {
        if (userCredential) {
          this.dialogRef.close();
        } else {
          this.resetForm();
        }
        //console.log(userCredential);
      });
  }

  getFormData(login: LoginData): void {
    this.loginData = login;
  }

  checkFormValidity(valid: boolean): void {
    if (valid) {
      this.formValid = valid;
    } else {
      if (this.loginData) {
        this.loginData = null;
      }
    }
  }

  resetForm(): void {
    this.resetForm$.next();
    this.formValid = null;
    this.loginData = null;
  }

  logout(): void {
    this.authService
      .logout()
      .then(() => this.dialogRef.close())
      .catch((e) => console.log(e.message));
  }

  deleteAccount(): void {
    this.user$.pipe(
      first(),
      tap(user => {
        if (user) {
          this.authService
            .deleteAccount(user)
            .then(() => this.dialogRef.close())
            .catch((e) => console.log(e.message));
        }
      })
    ).subscribe();
  }

  onSubmit(): void {
    this.user$.pipe(
      first(),
      tap(user => {
        if (user) {
          this.dialogRef.close();
        } else {
          if (this.loginData.register) {
            this.register(this.loginData);
          } else {
            this.login(this.loginData);
          }
        }
      }),
    ).subscribe();

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
